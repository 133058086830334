<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('cart2')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <p>{{ content.cart.title2 }}</p>
           <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="address_title">1. {{ content.cart.step5 }}</p>
          <span class="dash"></span>
         <ul class="payment-list">
           <li v-for="(payment, i) in paymentMethods" :key="i" :class="selectedMethod == payment.label ? 'active' : ''">
             <a @click="selectedMethod = payment.label">
               <img :src="img_url + payment.image">
             </a>
           </li>
         </ul>
        </div>
        <div class="footer">
          <div class="footer-container">
            <p class="msg">{{ msg }}</p>
            <div class="subtotal shipping">
              <p>{{ content.cart.subtotal }}<sup>*</sup></p>
              <p>
                <span>{{ checkoutInfo.items_cost.toFixed(2) }} CHF</span>
              </p>
            </div>
            <div class="subtotal shipping">
              <p>{{ content.cart.delivery }}</p>
              <p>
                <span>{{ checkoutInfo.delivery_cost.toFixed(2) }} CHF</span>
              </p>
            </div>
            
            <div>
              <p>{{ content.cart.total }}</p>
              <p>
                <span>{{ (checkoutInfo.items_cost + checkoutInfo.delivery_cost).toFixed(2) }} CHF</span>
              </p>
            </div>
            <a @click="pay()" class="white-btn checkout">{{
              content.cart.btn3
            }}</a>
            <p class="taxes"><sup>*</sup> {{ content.cart.taxes }}</p>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from '@/modules/api_calls'

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["backdoor"],
  data() {
    return {
      dialog: true,
      n: 0,
      name:"",
      selectedMethod: "",
      paymentMethods: [
        { label: "Visa", image:"visa.png", value: "visa" },
        { label: "MasterCard", image:"mastercard.png", value: "masterCard" },
        { label: "American Express", image:"americanexpress.png", value: "americanExpress" },
        { label: "Bank Tranfer", image:"bank_transfer.png", value: "transfer" }      ],
      apiCallResult: {},
      msg: "",
      promocode: {}
    };
  },
  async created() {
    // let app = this

    this.promocode = JSON.parse(window.sessionStorage.getItem("promocode"))

    this.n += 1
  },
  methods: {
    async pay() {
      this.msg = ""
      let lastOrder = JSON.parse(window.localStorage.getItem('checkoutInfo'))

      console.log(lastOrder.order_items)

      for(let i in lastOrder.order_items) {
          if(parseFloat(lastOrder.order_items[i].discount) == 0) {
            lastOrder.order_items[i].final_discount = parseFloat(this.promocode.discount).toFixed(2)
          }
          else {
            lastOrder.order_items[i].final_discount = parseFloat(lastOrder.order_items[i].discount * (1 - this.promocode.discount)).toFixed(2);
          }
      }

      window.localStorage.setItem('checkoutInfo', JSON.stringify(lastOrder))

      if(this.checkoutInfo.payment_method == "") {
        this.msg = "Please select your payment method"
        return
      }

      if(this.apiCallResult.resultado == "KO") {
        this.msg = this.apiCallResult.details
      }
      else {

        if(this.selectedMethod == "Bank Tranfer") {

          await this.$store.dispatch('sendOrder', this)

          var dt = new Date();
          dt.setDate(dt.getDate() + 3);
          lastOrder.delivery_date = dt.toISOString().slice(0,10)
          
          lastOrder.order_id = this.apiCallResult.order_id
          window.localStorage.setItem('lastOrder', JSON.stringify(lastOrder))

          let variables = JSON.parse(window.localStorage.getItem('lastOrder'))
          variables.user_name = window.localStorage.getItem('name')
          variables.user_email = window.localStorage.getItem('email')
          variables.phone = window.localStorage.getItem('phone')
          variables.payment_method = this.selectedMethod
          variables.total_cost = (parseFloat(variables.items_cost) + parseFloat(variables.delivery_cost)).toFixed(2)

          let mailObj = {
              lang: window.localStorage.getItem('pageLanguage'),
              subject: "Auris - Your order was received!",
              template: "Cliente-confirmacao_encomenda.html",
              variables,
              to: window.localStorage.getItem('email'),
          }

          await api_calls.call_api_post('mail/client', mailObj)

          mailObj.template = "Auris-nova_encomenda.html"
          mailObj.subject = "Auris - New order received!"

          await api_calls.call_api_post('mail/auris', mailObj)

          this.$router.push("/orders")
          // alert("Order Placed!")
          window.localStorage.removeItem('cart')
          window.localStorage.removeItem('checkoutInfo')

          this.$emit("update-modal", "cart4");
        }
        else {
          // let lastOrder = JSON.parse(window.localStorage.getItem('checkoutInfo'))
          // lastOrder.order_id = this.apiCallResult.order_id
          // window.localStorage.setItem('lastOrder', JSON.stringify(lastOrder))

          // window.localStorage.setItem('payment_method', this.selectedMethod)

          // this.$emit("update-modal", "cart-credit-card");

          this.pay_cc()
        }

      }

    },
    async pay_cc() {

      event.preventDefault();
      let app = this;

      app.msg = "Validating...";

      await app.$store.dispatch("sendOrder", app);

      let lastOrder = JSON.parse(
        window.localStorage.getItem("checkoutInfo")
      );

      var dt = new Date();
      dt.setDate(dt.getDate() + 3);
      lastOrder.delivery_date = dt.toISOString().slice(0, 10);

      lastOrder.order_id = app.apiCallResult.order_id;
      window.localStorage.setItem(
        "lastOrder",
        JSON.stringify(lastOrder)
      );

      // let lastOrder = app.lastOrderInfo
      let order_id = lastOrder.order_id;

      let dados = {
        order_id,
        order_items: lastOrder.order_items,
        delivery_cost: lastOrder.delivery_cost
      };

      app.paymentData = dados;

      await app.$store.dispatch("processPayment", app);

      return false;
    },
  },
  computed: {
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.active {
  border: 2px solid #d12627;
}
</style>