import Vue from 'vue'
import Vuex from 'vuex'
import resources from "@/resources/languages"

import api_calls from '@/modules/api_calls'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        img_domain: 'https://tools.linqode.com/tools/assets/auris/images/',
        content: resources,
        running: false,
        loggedIn: false,
        editingAddress: null
    },
    mutations: {
        UPDATE_ADDRESS(state, payload) {
            state.editingAddress = payload.editingAddress
        }
    },
    actions: {

        async collect(context, { app, endpoint }) {

            context.state.running = true

            let data = await api_calls.call_api_get(endpoint)

            app.items = data.data

            context.state.running = false
        },
        async collectSlides(context, { app, endpoint }) {

            context.state.running = true

            let data = await api_calls.call_api_get(endpoint)

            app.slideItems = data.data


        },
        async getSingle(context, { app, endpoint }) {

            context.state.running = true

            let data = await api_calls.call_api_get(endpoint)

            app.item = data.data[0]

            context.state.running = false
        },
        async logUserIn(context, app) {
            let method = "users/login"
            let data = await api_calls.call_api_post(method, app.userData)

            if (data.resultado == "OK") {
                context.state.loggedIn = true

                window.localStorage.setItem('name', data.name)
                window.localStorage.setItem('email', data.email)
                window.localStorage.setItem('phone', data.phone)
                window.localStorage.setItem('company', data.company)
                window.localStorage.setItem('lang', data.lang)
                window.localStorage.setItem('user_id', data.user_id)
                window.localStorage.setItem('privacy', data.privacy)
            } else {
                context.state.loggedIn = false
            }

            app.apiCallResult = data
        },
        async addUser(context, app) {
            let method = "users/add"
            let data = await api_calls.call_api_post(method, app.userData)
            app.apiCallResult = data

            if (data.resultado == "OK") {

                let variables = {}
                variables.user_name = app.userData.name
                variables.user_email = app.userData.email
                variables.phone = app.userData.phone

                let mailObj = {
                    lang: window.localStorage.getItem('pageLanguage'),
                    subject: "Auris - Your registration is completed",
                    template: "Cliente-confirmacao_registo.html",
                    variables,
                    to: app.userData.email,
                }

                await api_calls.call_api_post('mail/client', mailObj)

                mailObj.template = "Auris-novo_registo.html"
                mailObj.subject = "Auris - New user registred"

                await api_calls.call_api_post('mail/auris', mailObj)
            }
        },
        async updateUser(context, app) {
            let method = "users/edit"
            let data = await api_calls.call_api_put(method, app.userData)
            app.apiCallResult = data

            if (data.resultado == "OK") {
                window.localStorage.setItem('name', app.userData.name)
                window.localStorage.setItem('email', app.userData.email)
                window.localStorage.setItem('phone', app.userData.phone)
                window.localStorage.setItem('company', app.userData.company)
                window.localStorage.setItem('lang', app.userData.lang)
                window.localStorage.setItem('privacy', app.userData.privacy)
            }
        },
        async addAddress(context, app) {
            let method = "addresses/add"
            let data = await api_calls.call_api_post(method, app.addressData)
            app.apiCallResult = data
        },
        async selectAddress(context, app) {
            if (app.editingAddress != '0') {
                await context.commit('UPDATE_ADDRESS', app)
            }
            app.n += 1

            app.$emit("update-modal", 'new-address');
        },
        async updateAddress(context, app) {
            let method = "addresses/update"

            delete app.addressData.delivery_cost
            delete app.addressData.delivery_min_value

            if(app.addressData.main == true) {
                app.addressData.main = 1
            }
            else if(app.addressData.main == false) {
                app.addressData.main = 0
            }

            let data = await api_calls.call_api_put(method, app.addressData)
            app.apiCallResult = data
            if (data.resultado == "OK") {
                await context.commit('UPDATE_ADDRESS', { editingAddress: null })
                app.n += 1
            }
        },
        async eventRegister(context, app) {
            let method = "event/register-user"
            let data = await api_calls.call_api_post(method, app.apiBody)
            app.apiCallResult = data

            if (data.resultado == "OK") {
                app.dialog2 = true

                let variables = {}
                variables.user_name = window.localStorage.getItem('name')
                variables.user_email = window.localStorage.getItem('email')
                variables.phone = window.localStorage.getItem('phone')
                
                variables.event_name = app.event.title
                variables.event_location = app.event.location
                variables.event_date = app.event.date
                variables.event_hour = app.event.start_hour
                
                let mailObj = {
                    lang: window.localStorage.getItem('pageLanguage'),
                    subject: "Auris - Event registration confirmed",
                    template: "Cliente-confirmacao_inscrição_evento.html",
                    variables,
                    to: window.localStorage.getItem('email'),
                }

                await api_calls.call_api_post('mail/client', mailObj)

                mailObj.template = "Auris-nova_inscrição_evento.html"
                mailObj.subject = "Auris - User registred to event"

                await api_calls.call_api_post('mail/auris', mailObj)
            }
        },
        async sendOrder(context, app) {
            let method = "orders/add"
            let data = await api_calls.call_api_post(method, app.checkoutInfo)
            app.apiCallResult = data

            return data
        },
        async processPayment(context, app) {
            let method = "payment/create-checkout-session/" + app.paymentData.order_id
            let data = await api_calls.call_api_post(method, app.paymentData)
            
            if(typeof data.checkout_url == 'string') {
                //Cliente-confirmacao_encomenda.html

                let variables = JSON.parse(window.localStorage.getItem('lastOrder'))
                variables.user_name = window.localStorage.getItem('name')
                variables.user_email = window.localStorage.getItem('email')
                variables.phone = window.localStorage.getItem('phone')
                variables.payment_method = window.localStorage.getItem('payment_method')
                variables.total_cost = (parseFloat(variables.items_cost) + parseFloat(variables.delivery_cost)).toFixed(2)

                let mailObj = {
                    lang: window.localStorage.getItem('pageLanguage'),
                    subject: "Auris - Your order was received!",
                    template: "Cliente-confirmacao_encomenda.html",
                    variables,
                    to: window.localStorage.getItem('email'),
                }

                await api_calls.call_api_post('mail/client', mailObj)

                //Cliente-pagamento_encomenda.html

                mailObj.template = "Auris-nova_encomenda.html"
                mailObj.subject = "Auris - New order received!"

                await api_calls.call_api_post('mail/auris', mailObj)

                window.localStorage.removeItem('cart')
                window.localStorage.removeItem('checkoutInfo')

                window.location.href = data.checkout_url

            }

            // if (data.resultado == "Payed") {

            //     let order = {
            //         id: app.paymentData.order_id,
            //         payment_status: "1",
            //         order_status: "Being Processed"
            //     }
            //     await api_calls.call_api_put('orders/update', order)

            //     app.msg = "Your payment has been confirmed"

            //     //Cliente-confirmacao_encomenda.html

            //     let variables = JSON.parse(window.localStorage.getItem('lastOrder'))
            //     variables.user_name = window.localStorage.getItem('name')
            //     variables.user_email = window.localStorage.getItem('email')
            //     variables.phone = window.localStorage.getItem('phone')
            //     variables.payment_method = window.localStorage.getItem('payment_method')
            //     variables.total_cost = (parseFloat(variables.items_cost) + parseFloat(variables.delivery_cost)).toFixed(2)

            //     let mailObj = {
            //         lang: window.localStorage.getItem('pageLanguage'),
            //         subject: "Auris - Your order was received!",
            //         template: "Cliente-confirmacao_encomenda.html",
            //         variables,
            //         to: window.localStorage.getItem('email'),
            //     }

            //     await api_calls.call_api_post('mail/client', mailObj)

            //     //Cliente-pagamento_encomenda.html

            //     mailObj.template = "Auris-nova_encomenda.html"
            //     mailObj.subject = "Auris - New order received!"

            //     await api_calls.call_api_post('mail/auris', mailObj)

            //     window.localStorage.removeItem('cart')
            //     window.localStorage.removeItem('checkoutInfo')

            //     app.$router.push("/orders")

            //     app.$emit("update-modal", "")

            // } else if (data.resultado == "Reviewing") {
            //     app.msg = "Your payment is being reviewd. We'll contact you with the result as soon as possible"
            // } else {
            //     app.msg = data
            // }

            return data
        }


    },
    modules: {}
})
