<template>
  <v-row justify="center">
    <v-dialog
      v-model="cookies"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </v-toolbar>
        <section class="termos">
           <h2>{{content.footer.main_footer.footer4}}</h2>
           <div v-html="privacy.description"></div>
          <!-- <h2 class="bc-dark-pink">Privacy Policy</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p class="title bold">1. Title</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            euismod, ex eu pellentesque vulputate, dui tortor porttitor mauris,
            in rutrum orci nisi at lectus. Pellentesque dapibus arcu eu purus
            auctor convallis. Duis convallis sagittis elit vitae egestas. Sed
            tempus tincidunt mi vel cursus. Donec dictum mattis orci a
            vestibulum. Cras in porttitor mauris, ut varius ipsum. Etiam eu nisi
            faucibus, imperdiet purus vitae, congue nunc. Phasellus quis nunc ut
            arcu porttitor lobortis a et est. Aenean nec semper metus. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Suspendisse id turpis erat. Morbi et sollicitudin
            sapien. Vivamus dignissim eros et urna fringilla cursus.
          </p>
          <p class="title bold">2. Title</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            euismod, ex eu pellentesque vulputate, dui tortor porttitor mauris,
            in rutrum orci nisi at lectus. Pellentesque dapibus arcu eu purus
            auctor convallis. Duis convallis sagittis elit vitae egestas. Sed
            tempus tincidunt mi vel cursus. Donec dictum mattis orci a
            vestibulum. Cras in porttitor mauris, ut varius ipsum. Etiam eu nisi
            faucibus, imperdiet purus vitae, congue nunc. Phasellus quis nunc ut
            arcu porttitor lobortis a et est. Aenean nec semper metus. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Suspendisse id turpis erat. Morbi et sollicitudin
            sapien. Vivamus dignissim eros et urna fringilla cursus.
          </p>
          <p class="title bold">3. Title</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            euismod, ex eu pellentesque vulputate, dui tortor porttitor mauris,
            in rutrum orci nisi at lectus. Pellentesque dapibus arcu eu purus
            auctor convallis. Duis convallis sagittis elit vitae egestas. Sed
            tempus tincidunt mi vel cursus. Donec dictum mattis orci a
            vestibulum. Cras in porttitor mauris, ut varius ipsum. Etiam eu nisi
            faucibus, imperdiet purus vitae, congue nunc. Phasellus quis nunc ut
            arcu porttitor lobortis a et est. Aenean nec semper metus. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Suspendisse id turpis erat. Morbi et sollicitudin
            sapien. Vivamus dignissim eros et urna fringilla cursus.
          </p>
          <p class="title bold">4. Title</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            euismod, ex eu pellentesque vulputate, dui tortor porttitor mauris,
            in rutrum orci nisi at lectus. Pellentesque dapibus arcu eu purus
            auctor convallis. Duis convallis sagittis elit vitae egestas. Sed
            tempus tincidunt mi vel cursus. Donec dictum mattis orci a
            vestibulum. Cras in porttitor mauris, ut varius ipsum. Etiam eu nisi
            faucibus, imperdiet purus vitae, congue nunc. Phasellus quis nunc ut
            arcu porttitor lobortis a et est. Aenean nec semper metus. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Suspendisse id turpis erat. Morbi et sollicitudin
            sapien. Vivamus dignissim eros et urna fringilla cursus.
          </p>
          <p class="title bold">5. Title</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            euismod, ex eu pellentesque vulputate, dui tortor porttitor mauris,
            in rutrum orci nisi at lectus. Pellentesque dapibus arcu eu purus
            auctor convallis. Duis convallis sagittis elit vitae egestas. Sed
            tempus tincidunt mi vel cursus. Donec dictum mattis orci a
            vestibulum. Cras in porttitor mauris, ut varius ipsum. Etiam eu nisi
            faucibus, imperdiet purus vitae, congue nunc. Phasellus quis nunc ut
            arcu porttitor lobortis a et est. Aenean nec semper metus. Orci
            varius natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Suspendisse id turpis erat. Morbi et sollicitudin
            sapien. Vivamus dignissim eros et urna fringilla cursus.
          </p> -->
        </section>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["pp"],

  name: "cookies",
  data() {
    return {
      cookies: true,
    };
  },
  computed: {
    privacy() {
      let temp = {};

      temp = this.pp;
      if (typeof temp.content != "undefined" && typeof temp.content == "string") {
        let content = JSON.parse(temp.content);
        let lang = this.pageLanguage.toLowerCase()
        content = content[lang]

        for (let k in content) {
          temp[k] = content[k];
        }

        temp.content = content;
      }

      return temp;
    }
  },
  methods: {
    changeModal: async function (modal) {
      this.$emit("update-modal", modal);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
.termos {
  padding: 5vw;
  @include responsive(0, 768px) {
    padding: 40px;
  }
  @include responsive(0, 512px) {
    padding: 40px 20px;
  }
  h2 {
    color: $dark-grey!important;
    text-align: left;
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  p {
    text-align: left;
    margin-bottom:0!important;
  }
}

a {
  position: absolute;
  top: 20px;
  right: 30px;
  span {
    font-family: "icomoon";
    font-size: 15px;
    color: white;
  }
  .icon-icon-close:before {
    content: "\e903";
  }
}
</style>