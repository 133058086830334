export default {
    //production
    domain: 'auriscollection.com/auris-api-dev/public',

    //preview
    // domain: 'tools.linqode.com/auris-api/public',

    // GET
    call_api_get: async function(method) {
        let url = `https://${this.domain}/${method}`;

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        try {
            var response = await fetch(url, {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },

    // POST
    call_api_post: async function(method, apiBody) {

        let url = `https://${this.domain}/${method}`;

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        try {
            var response = await fetch(url, {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
                body: JSON.stringify(apiBody),
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },

    //PUT
    call_api_put: async function(method, apiBody) {
        let url = `https://${this.domain}/${method}`;

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        try {
            var response = await fetch(url, {
                method: "PUT",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
                body: JSON.stringify(apiBody),
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },

    call_api_post_files: async function(method, apiBody) {

        let url = `https://${this.domain}/${method}`;

        var myHeaders = new Headers();
        // myHeaders.append("content-type", "multipart/form-data");

        try {
            var response = await fetch(url, {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
                body: apiBody,
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },

    call_api_put_files: async function(method, apiBody) {

        let url = `https://${this.domain}/${method}`;

        var myHeaders = new Headers();
        // myHeaders.append("content-type", "multipart/form-data");

        try {
            var response = await fetch(url, {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
                body: apiBody,
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },


    call_api_mail: async function(apiBody) {

        let url = `https://tools.linqode.com/mailer/index.php`;

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        try {
            var response = await fetch(url, {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                credentials: "same-origin",
                body: JSON.stringify(apiBody),
            });

            var result = await response.json();

            return result;
        } catch (error) {
            console.error("Error:", error);
            return "Error: " + error;
        }
    },
};