import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#D12627',
            secondary: '#C12938',
            optional: '#575756',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      }
});

var VueScrollTo = require('vue-scrollto');
 
Vue.use(VueScrollTo)

