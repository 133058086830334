<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('addresses')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <div class="inputs">
            <v-switch
              v-model="addressData.main"
              :label="content.address.main"
            ></v-switch>
            <v-text-field
              v-model="addressData.name"
              :label="content.address.label_name + '*'"
            ></v-text-field>
            <v-text-field
              v-model="addressData.street"
              :label="content.address.label_street + '*'"
            ></v-text-field>
            <v-text-field
              v-model="addressData.door"
              :label="content.address.label_suite + '*'"
            ></v-text-field>
            <v-text-field
              v-model="addressData.zip"
              :label="content.address.label_zip_code + '*'"
            ></v-text-field>
            <v-select
              :label="content.address.label_state + '*'"
              :items="content.caton"
              item-text="name"
              return-object
              v-model="addressData.state"
            ></v-select>
            <v-text-field
              v-model="addressData.city"
              :label="content.address.label_city + '*'"
            ></v-text-field>
            <v-text-field
              v-model="addressData.country"
              :label="content.address.label_country + '*'"
            ></v-text-field>
            <v-text-field
              v-model="addressData.extra_info"
              :label="content.address.label_extra_info"
            ></v-text-field>
            <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div class="check-text">
                  {{content.registo.privacy1}}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        v-on:click="openFooter('privacy')"
                        @click.stop
                        v-on="on"
                        class="termos"
                        href="#"
                      >{{content.registo.privacy2}}</a>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <a v-if="editingAddress" @click="deleteAddress" class="button-fill">
              {{ content.addresses.btn_delete }}
            </a>
          </div>
        </div>
        <div class="footer">
          <div class="footer-container">
            <p class="msg">{{ msg }}</p>
            <a @click="updateAddress" class="white-btn" v-if="editingAddress">
              {{ content.address.btn_save }}
            </a>
            <a @click="addAddress" class="white-btn" v-else>
              {{ content.address.btn_save }}
            </a>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      item: {
        name: "",
        street: "",
        door: "",
        zip: "",
        state: "",
        city: "",
        country: "Switzerland",
        extra_info:"",
        main: false,
      },
      checkbox: "",
      show: "",
      msg: "",

      apiCallResult: {},

      n: 0,
    };
  },
  computed: {
    addressData: {
      get: function () {
        this.n;
        if (this.$store.state.editingAddress != null) {
          return this.$store.state.editingAddress;
        } else {
          return this.item;
        }
      },
      set: function (newVal) {
        this.n;
        this.item = newVal;
      },
    },
    editingAddress() {
      this.n;
      return this.$store.state.editingAddress != null;
    },
  },
  methods: {
    changeModal: async function (modal) {
      this.$store.commit("UPDATE_ADDRESS", { editingAddress: null });
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },

    deleteAddress(){
      let app = this;
      let r= confirm (this.content.forms.delete_validation)

      if(r == true){

      app.addressData.status = 0;

      app.addressData.user_id = window.localStorage.getItem("user_id");

      this.$store.dispatch("updateAddress", this);

      app.$emit("update-modal", "addresses");
      }
    },

    async addAddress() {
      let app = this;

      this.msg = this.content.forms.validation;
      let error = false;

      // for(let item in app.addressData) {
      //   if(app.addressData[item] == "" && item != "main") {
      //     error = true
      //     this.msg = "All fields are mandatory"
      //   }
      // }
      if (this.addressData.name == "") {
        error = true;
        this.msg = this.content.forms.address_name;
      } else if (this.addressData.street == "") {
        error = true;
        this.msg = this.content.forms.address_street;
      } else if (this.addressData.door == "") {
        error = true;
        this.msg = this.content.forms.address_app;
      } else if (this.addressData.zip == "") {
        error = true;
        this.msg = this.content.forms.address_zip;
      } else if (this.addressData.city == "") {
        error = true;
        this.msg = this.content.forms.address_city;
      } else if (this.addressData.state == "") {
        error = true;
        this.msg = this.content.forms.address_state;
      } else if (this.addressData.country == "") {
        error = true;
        this.msg = this.content.forms.address_country;
      }
      else if (
        this.checkbox == false ||
        this.checkbox == 0 ||
        this.checkbox == null ||
        this.checkbox == ""
      ) {
        error = true;
        this.msg = this.content.forms.terms;
      }
      if (error) {
        return false;
      }

      app.addressData.user_id = window.localStorage.getItem("user_id");

      await this.$store.dispatch("addAddress", this);

      if (this.apiCallResult.resultado != "OK") {
        this.msg = this.apiCallResult.details;
      } else {
        this.msg = this.content.forms.validation_ok2;
        app.$emit("update-modal", "addresses");
      }
    },
    async updateAddress() {
      let app = this;

      this.msg = this.content.forms.address_name;
      let error = false;

      // for (let item in app.addressData) {
      //   if (app.addressData[item] == "" && item != "main") {
      //     error = true;
      //     this.msg = "All fields are mandatory";
      //   }
      // }

      if (this.addressData.name == "") {
        error = true;
        this.msg = this.content.forms.address_name;
      } else if (this.addressData.street == "") {
        error = true;
        this.msg = this.content.forms.address_street;
      } else if (this.addressData.door == "") {
        error = true;
        this.msg = this.content.forms.address_app;
      } else if (this.addressData.zip == "") {
        error = true;
        this.msg = this.content.forms.address_zip;
      } else if (this.addressData.city == "") {
        error = true;
        this.msg = this.content.forms.address_city;
      } else if (this.addressData.state == "") {
        error = true;
        this.msg = this.content.forms.address_state;
      } else if (this.addressData.country == "") {
        error = true;
        this.msg = this.content.forms.address_country;
      } else if (
        this.checkbox == false ||
        this.checkbox == 0 ||
        this.checkbox == null ||
        this.checkbox == ""
      ) {
        error = true;
        this.msg = this.content.forms.terms;
      }
      if (error) {
        return false;
      }

      app.addressData.user_id = window.localStorage.getItem("user_id");

      await this.$store.dispatch("updateAddress", this);

      if (this.apiCallResult.resultado != "OK") {
        this.msg = this.apiCallResult.details;
      } else {
        this.msg = this.content.forms.validation_ok2;
        app.$emit("update-modal", "addresses");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.check-text{
  a{
    text-align: left;
  }
}

.button-fill{
  margin: 10px auto;
}
</style>