<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="title">{{content.registo.title}}</p>
          <div class="inputs">
            <v-text-field v-model="name" :label="content.registo.label_name + '*'"></v-text-field>
            <v-text-field v-model="email" :label="content.registo.label_email + '*'"></v-text-field>
            <v-text-field v-model="phone" :label="content.registo.label_phone + '*'"></v-text-field>
            <v-text-field v-model="company" :label="content.registo.label_company"></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              :label="content.details.label_password + '*'"
              @click:append="show = !show"
              autocomplete="new-password"
            ></v-text-field>
            <v-text-field
              v-model="password2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              :label="content.details.label_password_2 + '*'"
              @click:append="show2 = !show2"
              autocomplete="new-password"
            ></v-text-field>

            <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div class="check-text">
                  {{content.registo.privacy1}}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        v-on:click="openFooter('privacy')"
                        @click.stop
                        v-on="on"
                        class="termos"
                        href="#"
                      >{{content.registo.privacy2}}</a>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="checkbox2">
              <template v-slot:label>
                <div class="check-text">
                  {{content.registo.privacy3}}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="footer footer-registo">
          <div class="footer-container">
            <p class="msg">{{msg}}</p>
            <a @click="addUser" class="white-btn">{{content.registo.title}}</a>
            <a v-on:click="changeModal('login')" class="line-btn"
              >{{content.registo.login}}</a
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      name: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
      company: "",
      checkbox: "",
      checkbox2: "",
      show: "",
      show2: "",
      msg: "",
    };
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    async addUser() {
      let app = this

      this.msg = this.content.forms.validation
      if (this.name == "") {
        this.msg = this.content.forms.name;
      }
      else if (this.email == "") {
        this.msg = this.content.forms.email;
      }
      else if (this.phone == "") {
        this.msg = this.content.forms.phone;
      }
      else if (this.password == "") {
        this.msg = this.content.forms.password;
      }
      else if (this.password2 == "") {
        this.msg = this.content.forms.password2;
      }
      else if(this.password2 != this.password) {
        this.msg = this.content.forms.password3;
      }
      else if (this.checkbox == false || this.checkbox == 0 || this.checkbox == null || this.checkbox == '') {
        this.msg = this.content.forms.terms;
      }
      else if (this.checkbox2 == false || this.checkbox2 == 0 || this.checkbox2 == null || this.checkbox2 == '') {
        this.msg = this.content.forms.terms;
      }
      else {

        this.userData = {
          name: this.name, 
          email: this.email,
          phone: this.phone,
          password: this.password,
          company: this.company,
          id: window.localStorage.getItem('user_id'),
          privacy: this.checkbox,
          age: this.checkbox2
        }
        
        await this.$store.dispatch("addUser", this);

        if (this.apiCallResult.resultado != "OK") {
          this.msg = this.apiCallResult.details;
        } else {
          this.msg = this.apiCallResult.details;
          setTimeout(function() {
            app.$emit("update-modal", "");
          }, 1000)
        }

      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>