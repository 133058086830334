export default {
    data: () => ({
        name: "computedActions",
        selectedLang: window.localStorage.getItem("pageLanguage"),
        langs: ["EN", "FR", "PT"],
    }),
    computed: {
        version() {
            let temp = ""
            return temp
        },
        dados() {
            let final = [];
            let temp = {};
            
            // let app = this
            this.n

            for (let item of this.items) {

                temp = item;

                // CONTENT DECODE
                // if (typeof item.content != 'undefined' && typeof item.content != 'object') {
                //     let content = JSON.parse(item.content)
                //     temp.content = content.en
                // }

                if (typeof temp.content != 'undefined') {
                    let content = JSON.parse(temp.content)
                    let lang = this.pageLanguage.toLowerCase()
                    content = content[lang]

                    for (let k in content) {
                        temp[k] = content[k]
                    }

                    temp.content = content
                }
                if (typeof temp.main != 'undefined') {
                    if (temp.main == '0') {
                        temp.main = false
                    } else {
                        temp.main = true
                    }
                }
                if (typeof temp.order_status != 'undefined') {
                    let base = {
                        "shipped": {
                            "en": "Shipped",
                            "fr": "Expédié",
                            "pt": "Enviado"
                        }, 
                        "received": {
                            "en": "Received",
                            "fr": "Reçue",
                            "pt": "Recebido"
                        }, 
                        "ready for pickup": {
                            "en": "Ready for pickup",
                            "fr": "Prêt pour le ramassage",
                            "pt": "Pronto para levantamento"
                        }, 
                        "refunded": {
                            "en": "Refunded",
                            "fr": "Remboursé",
                            "pt": "Devolvido"
                        }, 
                        "being processed": {
                            "en": "Being processed",
                            "fr": "En cours de traitement",
                            "pt": "Em processamento"
                        },
                        "awaiting payment": {
                            "en": "Awaiting payment",
                            "fr": "En attente de paiement",
                            "pt": "A aguardar pagamento"
                        }
                    }

                    let lang = this.pageLanguage.toLowerCase()
                    let status = temp.order_status.toLowerCase()

                    console.log(status)
                    
                    temp.order_status = base[status][lang] || "not"
                }

                final.push(temp);
            }

            return final;
        },
        img_url() {
            return this.$store.state.img_domain;
        },
        content() {
            this.n
            let lang = this.pageLanguage.toLowerCase()
            return this.$store.state.content[lang];
        },
        checkoutInfo() {
            this.n;
            let temp = JSON.parse(window.localStorage.getItem("checkoutInfo")) || {};

            temp.payment_method = this.selectedMethod

            temp.articles = temp.order_items.length

            temp.order_items = JSON.stringify(temp.order_items)
            temp.delivery_address = JSON.stringify(temp.delivery_address)
            temp.billing_address = JSON.stringify(temp.billing_address)

            temp.order_status = "Awaiting Payment"
            temp.user_id = window.localStorage.getItem('user_id')

            return temp;
        },
        lastOrderInfo() {
            this.n;
            let temp = JSON.parse(window.localStorage.getItem("lastOrder")) || {};

            temp.articles = temp.order_items.length

            temp.order_items = JSON.stringify(temp.order_items)
            temp.delivery_address = JSON.stringify(temp.delivery_address)
            temp.billing_address = JSON.stringify(temp.billing_address)
            temp.user_id = window.localStorage.getItem('user_id')

            return temp;
        },
        pageLanguage: {
            get() {
                this.n
                this.selectedLang = window.localStorage.getItem("pageLanguage") || "EN"
                let temp = this.selectedLang

                return temp
            },
            set(val) {
                this.selectedLang = val

                window.localStorage.setItem("pageLanguage", val)

                this.n += 1

                window.location.reload()
            }
        },
    }
}