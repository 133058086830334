<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8">
      <div id="form-container" class="form-container">
        <div class="header">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
          <p>{{name}}</p>
        </div>
        <div class="content-dialog user">
          <ul>
        <li>
          <a v-on:click="changeModal('details')">{{
            content.header.secondary_menu.menu1
          }}</a>
        </li>
        <li>
          <router-link to="/orders"><a v-on:click="changeModal('')">{{
            content.header.secondary_menu.menu2
          }}</a></router-link>
        </li>
        <li>
          <a v-on:click="changeModal('addresses')">{{
            content.header.secondary_menu.menu3
          }}</a>
        </li>
      </ul>
        </div>
        <div class="footer">
          <div class="footer-container user">
            <a v-on:click="logout()" class="white-btn" >{{ content.header.secondary_menu.menu4 }}</a>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      name: window.localStorage.getItem("name"),
    };
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    async logout() {
      let cart = window.localStorage.getItem('cart')

      window.localStorage.clear();

      window.localStorage.setItem('cart', cart)
      
      this.$emit("update-modal", "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>