import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/online_store/:search?/:region?',
        name: 'Online Store',
        component: () =>
            import ('../views/Store.vue')
    },
    {
        path: '/product/:id/:name?',
        name: 'Wine Page',
        component: () =>
            import ('../views/Product.vue')
    },
    {
        path: '/news-and-events',
        name: 'News & Events',
        component: () =>
            import ('../views/News-Events.vue')
    },
    {
        path: '/event/:id/:name?',
        name: 'News or Events',
        component: () =>
            import ('../views/Event.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () =>
            import ('../views/Contacts.vue')
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () =>
            import ('../views/My-orders.vue')
    },
    {
        path: '/:faqs?',
        name: 'Home',
        component: Home
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

})

export default router