<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="title">{{ content.addresses.title }}</p>
          <div class="addresses">
            <ul>
              <li v-for="(address, i) in dados" :key="i">
                <p class="name">
                  {{ address.name
                  }}<br><span v-if="address.main == 1" class="main">
                    ({{ content.addresses.main }})</span
                  >
                </p>
                <p>{{ address.street }}</p>
                <p>{{ address.zip_code }}</p>
                <p>{{ address.city }}, {{ address.country }}</p>
                <a class="edit" v-on:click="selectAddress(address)"
                  ><span class="icon-icon-arrow-right"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <div class="footer-container">
            <a v-on:click="changeModal('new-address')" class="white-btn">{{
              content.addresses.btn_save
            }}</a>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      items: [],
      editingAddress: 0,

      initializeEP: "addresses/user-list/",
    };
  },
  async mounted() {
    await this.initialize(this.initializeEP + window.localStorage.getItem('user_id'))
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    async selectAddress(address) {
      this.editingAddress = address
      await this.$store.dispatch("selectAddress", this);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>