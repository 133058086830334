<template>
  <section id="ourselection" class="our-selection">
      <h3>{{content.our_selection.title1}}</h3>
      <h2>{{content.our_selection.title2}}</h2>
      <hide-at
        breakpoint="small"
        :breakpoints="{ small: 1024, medium: 1050, large: 3000 }"
      >
      <ul>
          <li v-for="(wine, i) in dados" :key="i"><ProductPreview :wine="dados[i]" @update-cart="updateCart()" /></li>
      </ul>
      </hide-at>
      <show-at
        breakpoint="small"
      >
      <v-carousel hide-delimiters style="z-index:2">
      <v-carousel-item
        v-for="(wine, i) in dados" :key="i"
      >
      <ProductPreview :wine="dados[i]" @update-cart="updateCart()" />
      </v-carousel-item>
      </v-carousel>
      </show-at>
      <router-link to="/online_store" v-scroll-to="{ el: '#top'}" class="button-fill">{{content.our_selection.btn}}</router-link>
  </section>
</template>

<script>
import ProductPreview from "@/components/Product-preview";
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import {hideAt, showAt} from "vue-breakpoints";

export default {
  name: "Home",
  mixins: [computedMixins, methodsMixins],
  components: {
    ProductPreview,
    showAt,
    hideAt
  },
  data() {
    return {
      items: [
        // {
        //   name: "Humilitas Reserve",
        //   year: "2010",
        //   type: "Red",
        //   price: 50.00,
        //   varieties: "Touriga Nacional",
        //   img: "garrafa1.png",
        //   id: 1,
        //   box_quantity: 1,
        // },
        // {
        //   name: "Humilitas Reserve 2",
        //   year: "2010",
        //   type: "Red",
        //   price: 50.00,
        //   varieties: "Touriga Nacional",
        //   img: "garrafa1.png",
        //   id: 2,
        //   box_quantity: 3,
        // },
        // {
        //   name: "Humilitas Reserve 3",
        //   year: "2010",
        //   type: "Red",
        //   price: 50.00,
        //   varieties: "Touriga Nacional",
        //   img: "garrafa1.png",
        //   id: 3,
        //   box_quantity: 6,
        // },
      ],
      // initializeEP: "products/list-our-selection"
    };
  },
  created() {
    this.initialize(this.initializeEP)
  },
  computed: {
    initializeEP() {
      let temp = "products/"+this.version+"list-our-selection"
      return temp
    },
  },
  methods: {
    async updateCart() {
      this.$emit("update-cart")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.our-selection{
    text-align: center;
    position: relative;
    padding: 80px;
    @include responsive(0, 512px){
      padding: 60px 20px;
    }
    h3{
      position: relative;
      z-index: 2;
    }
    h2{
      text-transform: uppercase;
      color: $dark-grey;
      position: relative;
      z-index: 2;
    }
    ul{
      margin-top: 30px;
        li{
            display: inline-block;
        }
    }
    a{
      margin: 0 auto;
      margin-top: 20px;
    }
}
.our-selection::after{
      content: '';
      width: 100%;
      background-color: $light-greyII;
      height: 50%;
      position: absolute;
      top: 0;
      left:0;
      z-index:1;
    }
</style>