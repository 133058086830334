<template>
  <div class="card-preview">

    <div class="wine-preview">
      <a :href="'/product/' + wine.id + '/' + wine.name.trim().replaceAll(' ', '-')">
      <img
      v-if="wine.new == 1"
      class="new-stamp"
      :src="img_url + 'new-stamp.png'"
    />
        <div class="image-container">
          <div class="hover">
            <p>{{ wine.content.name }}</p>
            <p><span v-if="wine.box_quantity > 1"></span>{{ wine.it }} lt</p>
            <a
              v-if="wine.stock > 0"
              fab
              small
              dark
              elevation="0"
              class="add"
              color="primary"
              @click="addProduct(wine)"
            >
              <span class="icomoon icon-icon-cart-add"></span>
            </a>
          </div>
          <img :src="img_url + wine.img" />
          <a
            v-if="wine.stock > 0"
            fab
            small
            dark
            elevation="0"
            class="add mobile"
            color="primary"
            @click="addProduct(wine)"
          >
            <span class="icomoon icon-icon-cart-add"></span>
          </a>
        </div>
        <p class="name">{{ wine.content.name }}<br /></p>
        <p class="two">{{ wine.year }} | {{ wine.content.type }}</p>
        <div v-if="wine.discount == 0">
          <p>{{ (wine.price * 1).toFixed(2) }} CHF</p>
        </div>
        <div v-else>
          <p>
            <span class="underline">{{ (wine.price * 1).toFixed(2) }} CHF</span>
            <span>
              {{ (wine.price * (1 - wine.discount)).toFixed(2) }} CHF</span
            >
          </p>
        </div>
        <p class="sold-out" v-if="wine.stock == 0">
          {{ content.product.btn6 }}
        </p>
      </a>
    </div>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  props: ["wine"],
  data() {
    return {
      n: 0
    };
  },
  computed: {
    img_url() {
      return this.$store.state.img_domain;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
  },
  methods: {
    addProduct: async function (produto) {
      this.n += 1
      event.preventDefault();
      this.n += 1;
      if ((await this.checkProdInCart(produto.id)) == true) {
        return false;
      }

      produto.quantity = 1;

      if (produto.box_quantity == "1") {
        produto.quantitySelected = "bottle";
      } else {
        produto.quantitySelected = "box";
      }

      this.materiaisCarrinho.push(produto);
      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    async checkProdInCart(id) {
      this.n += 1;
      this.$emit("update-cart");
      let temp = await this.materiaisCarrinho.reduce(function (
        acumulador,
        valorAtual
      ) {
        acumulador.push(valorAtual.id);

        return acumulador;
      },
      []);
      return temp.indexOf(id) > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.wine-preview {
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 30px 35px;
  width: 250px;
  vertical-align: top;
  @include responsive(0, 1150px) {
    margin: 30px 15px;
  }
  .image-container {
    width: 200px;
    height: 350px;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
    @include responsive(0, 512px) {
      height: 300px;
    }

    .hover {
      background-color: $red;
      width: 190px;
      height: 250px;
      padding: 30px 20px;
      position: absolute;
      top: 50%;
      right: 50% !important;
      transform: translateY(-50%);
      z-index: 5;
      text-align: left;
      opacity: 0;
      transition: 0.9s;
      @include responsive(0, 512px) {
        display: none;
      }
      p {
        color: white;
        font-weight: 600;
        max-width: 100px;
        text-align: left;
        margin-bottom: 10px;
      }
      p:nth-child(2) {
        font-weight: 400;
      }
      button {
        position: absolute;
        bottom: 20px;
        left: 20px;
      }
    }
    img {
      height: 100%;
      z-index: 10;
      position: relative;
    }
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
  .two {
    letter-spacing: 3px;
    font-weight: 400;
    line-height: 22px;
  }
  .name {
    font-family: $font1;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
    span {
      font-family: $font2;
      font-weight: 300;
      font-size: 14px;
    }
  }
  &:hover {
    .image-container {
      .hover {
        opacity: 1;
      }
    }
  }
}

.add {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: white;
  text-align: center;
  .icomoon {
    color: $red;
    line-height: 50px;
    font-size: 22px;
  }
  &.mobile {
    display: none;
    position: absolute;
    bottom: -15px;
    right: 18%;
    left: auto;
    z-index: 10;
    background-color: $red;
    @include responsive(0, 512px) {
      display: block;
    }
    .icomoon {
      color: white;
    }
  }
}

.underline {
  color: $red;
  text-decoration: line-through;
}

.sold-out {
  color: $red;
  font-weight: 400 !important;
  letter-spacing: 2px;
  font-size: 12px !important;
  text-transform: uppercase;
}

.icon-icon-cart-add {
  font-weight: 600;
}

.card-preview{
  position: relative;
}

.new-stamp {
  position: absolute;
  top: -20px;
  right: 30px;
  width: 60px;
  z-index: 2;
  @include responsive(0, 1700px){
    right: 10px;
  }
}
</style>