<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('cart2')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <p>{{ content.cart.title2 }}</p>
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="address_title">{{ content.cart.step6 }}</p>
          <span class="dash"></span>
          <div class="bank-transfer">
          <p>{{ content.cart.step7 }}</p>
          <ul>
            <li><span>{{ content.cart.step8 }}</span></li>
            <li><span>{{ content.cart.step10 }}</span> {{ lastOrderInfo.order_id }}</li>
            <li><span>{{ content.cart.step11 }}</span> {{ (lastOrderInfo.items_cost + lastOrderInfo.delivery_cost).toFixed(2) }} CHF</li>
            <li><span>{{ content.cart.step12 }}</span> {{ content.cart.step12_1 }}</li>
            <li><span>{{ content.cart.step13 }}</span> {{ content.cart.step13_1 }}</li>
            <li><span>{{ content.cart.step14 }}</span> {{ content.cart.step14_1 }}</li>
            <li><span>{{ content.cart.step15 }}</span> {{ content.cart.step15_1 }}</li>
          </ul>
          </div>
        </div>
        <div class="footer">
          <div class="footer-container">
            <a @click="changeModal('')" class="white-btn checkout">{{
              content.cart.title
            }}</a>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

// import api_calls from '@/modules/api_calls'

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["backdoor"],
  data() {
    return {
      dialog: true,
      n: 0,
      name: "",
      selectedMethod: "",
      paymentMethods: [
        { label: "Visa", image: "visa.png", value: "visa" },
        { label: "MasterCard", image: "mastercard.png", value: "masterCard" },
        {
          label: "American Express",
          image: "americanexpress.png",
          value: "americanExpress",
        },
        {
          label: "Bank Tranfer",
          image: "bank_transfer.png",
          value: "transfer",
        },
      ],
      apiCallResult: {},
      msg: "",
    };
  },
  async created() {
    // let app = this

    this.n += 1;
  },
  methods: {
    // async pay() {
    //   this.msg = "";

    //   if (this.checkoutInfo.payment_method == "") {
    //     this.msg = "Please select your payment method";
    //     return;
    //   }

    //   await this.$store.dispatch("sendOrder", this);

    //   if (this.apiCallResult.resultado == "KO") {
    //     this.msg = this.apiCallResult.details;
    //   } else {
    //     alert("Order Placed!");
    //     window.localStorage.removeItem("cart");
    //     window.localStorage.removeItem("checkoutInfo");
    //     this.$emit("update-modal", "");
    //     this.$router.push("/orders");
    //   }
    // },
  },
  computed: {
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.active {
  border: 2px solid #d12627;
}
</style>