<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="title">{{content.login.title}}</p>
          <div class="inputs">
            <v-text-field v-model="userData.email" :label="content.login.label_email + '*'"></v-text-field>
            <v-text-field
              v-model="userData.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              :label="content.login.label_password + '*'"
              @click:append="show = !show"
            ></v-text-field>
            <a class="line-btn" v-on:click="changeModal('forgot-password')"
              >{{content.login.recover}}</a
            >
          </div>
        </div>
        <div class="footer footer-registo">
          <div class="footer-container">
            <p class="msg">{{msg}}</p>
            <a @click="logIn()" class="white-btn">{{content.login.title}}</a>
            <a v-on:click="changeModal('registo')" class="line-btn"
              >{{content.login.create}}</a
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      userData: {
        email: "",
        password: "",
      },
      show: "",
      msg: "",

      apiCallResult: {},

      notEmptyFields: {
      
    }
    };
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    async logIn() {
      this.msg = this.content.forms.validation;
      if (this.userData.email == '') {
        this.msg = this.content.forms.email;
      } else if(this.userData.password == ""){
        this.msg = this.content.forms.password
      }
      else {
        await this.$store.dispatch("logUserIn", this);

        if (this.apiCallResult.resultado != "OK") {
          this.msg = this.apiCallResult.details;
        } else {
          this.msg = this.content.forms.validation_ok;
          window.localStorage.setItem("loggedIn", true);

          this.$emit("update-modal", "");
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>