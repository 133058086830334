<template class="search">
  <v-dialog v-model="dialog_search" persistent overlay-opacity="0.95">
    <div class="search">
    <a class="close" v-on:click="changeModal('')"><span class="icon-icon-close"></span></a>
    <v-row class="search-row" justify="center">
      <v-col cols="6">
        <v-text-field
          v-model="search"
          :label="content.search.search"
          @keyup.enter="runSearch()"
          dark
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <a @click="runSearch()" class="button-fill">{{ content.search.btn }}</a>
      </v-col>
    </v-row>
    </div>
  </v-dialog>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog_search: true,
      search: "",
    };
  },
  methods: {
    async runSearch() {
      this.$router.push(`/online_store/${this.search}`)
      this.$emit("update-modal", "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.close {
  position: absolute;
  right: 60px;
  @include responsive(0, 512px){
    right: 40px;
  }
  span {
    font-family: "icomoon";
    font-size: 15px;
    color: white;
  }
  .icon-icon-close:before {
    content: "\e903";
  }
}

.button-fill {
  @include tp-a-btn(fit-content, 50px, 13px, $dark-grey, white, 0, white, $red);
  display: inline-block;
  letter-spacing: 3px;
  font-weight: 400;
  padding: 0 40px;
  color: $dark-grey !important;
  border: 1px solid white;
  margin: 0 auto;
  &:hover {
    color: $red !important;
  }
  @include responsive(0, 512px){
    margin: 0 auto;
  }
}

.search-row{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0 0 30px;
  @include responsive(0, 512px){
    display: block!important;
    .col-6{
      max-width: 90%!important;
    }
     .col-2{
      max-width: 90%!important;
      text-align: center;
    }
  }
}
.search{
  width: 100vw;
  height: 90vh;
  max-width: 100vw;
}
</style>