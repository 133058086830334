<template>
  <v-row justify="center">
    <v-dialog
      v-model="cookies"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </v-toolbar>
        <section class="termos">
          <h2>FAQ's</h2>
          <template>
            <v-expansion-panels focusable>
              <v-expansion-panel v-for="(item, i) in dados" :key="i">
                <v-expansion-panel-header
                  v-html="item.question"
                ></v-expansion-panel-header>
                <v-expansion-panel-content v-html="item.answer">
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template></section></v-card></v-dialog
  ></v-row>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  name: "faqs",
  data() {
    return {
      cookies: true,
      items: [],

      // initializeEP: "faqs/bo-list-all",
    };
  },
  mounted() {
    this.initialize(this.initializeEP);
  },
  computed: {
     initializeEP() {
      let temp = "faqs/"+this.version+"list-all"
      return temp
    },
  },
  methods: {
    changeModal: async function (modal) {
      this.$emit("update-modal", modal);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";
.v-expansion-panel-header {
  p {
    color: $red;
    font-weight: 600;
    text-align: left!important;
    width: 100%;
  }
}
.v-expansion-panel-content {
  p {
    padding-top: 10px;
     width: 100%;
  }
}
.termos {
  padding: 5vw;
  @include responsive(0, 768px) {
    padding: 40px;
  }
  @include responsive(0, 512px) {
    padding: 40px 20px;
  }
  h2 {
    color: $dark-grey;
    text-align: left;
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  
}

a {
  position: absolute;
  top: 20px;
  right: 30px;
  span {
    font-family: "icomoon";
    font-size: 15px;
    color: white;
  }
  .icon-icon-close:before {
    content: "\e903";
  }
}
</style>