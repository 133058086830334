<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('cart3')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <p>{{ content.cart.title2 }}</p>
          <!-- <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a> -->
        </div>
        <div class="content-dialog">
          <p class="address_title">1. {{ content.cart.step16 }}</p>
          <!-- <ul class="payment-list details">
            <li
              v-for="(payment, i) in paymentMethods"
              :key="i"
              :class="selectedMethod == payment.label ? 'active' : ''"
            >
              <img v-if="selectedMethod == payment.label" :src="img_url + payment.image" />
            </li>
          </ul> -->

          <!-- <div class="inputs">
            <v-text-field
              data-brick="card-number"
              label="Card Number"
              v-model="card_number"
            ></v-text-field>

            <v-text-field
              data-brick="card-expiration-month"
              size="2"
              label="Expiration Month (MM)"
              v-model="card_expiration_month"
            ></v-text-field>
            <v-text-field
              data-brick="card-expiration-year"
              size="4"
              label="Expiration Year (YYYY)"
              v-model="card_expiration_year"
            ></v-text-field>

            <v-text-field
              data-brick="card-cvv"
              type="password"
              label="CVV/CVC"
              v-model="card_cvv"
            ></v-text-field>
            <input name="email" type="hidden" id="email" :value="email" />
          </div> -->
        </div>
        <div class="footer">
          <div class="footer-container">
            <p class="msg">{{ msg }}</p>
            <div class="subtotal shipping">
              <p>{{ content.cart.subtotal }}<sup>*</sup></p>
              <p>
                <span>{{ checkoutInfo.items_cost.toFixed(2) }} CHF</span>
              </p>
            </div>
            <div class="subtotal shipping">
              <p>{{ content.cart.delivery }}</p>
              <p>
                <span>{{ checkoutInfo.delivery_cost.toFixed(2) }} CHF</span>
              </p>
            </div>
            
            <div>
              <p>{{ content.cart.total }}</p>
              <p>
                <span>{{ (checkoutInfo.items_cost + checkoutInfo.delivery_cost).toFixed(2) }} CHF</span>
              </p>
            </div>
            <a @click="pay()" class="white-btn checkout">{{
              content.cart.btn3
            }}</a>
            <p class="taxes"><sup>*</sup> {{ content.cart.taxes }}</p>

          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

// import api_calls from '@/modules/api_calls'

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["backdoor"],
  data() {
    return {
      dialog: true,
      n: 0,
      name: "",
      selectedMethod: "credit-card",
      email: window.localStorage.getItem("email"),
      apiCallResult: {},
      msg: "",

      card_number: "",
      card_expiration_month: "",
      card_expiration_year: "",
      card_cvv: "",

      paymentData: {},
      paymentMethods: [
        { label: "Visa", image: "visa.png", value: "visa" },
        { label: "MasterCard", image: "mastercard.png", value: "masterCard" },
      ],

      // chargeEP: "https://tools.linqode.com/auris-api/public/payment/charge"
    };
  },
  async created() {
    // let app = this

    this.n += 1;
  },
  methods: {
    async pay() {

      event.preventDefault();
      let app = this;

      app.msg = "Validating...";

      await app.$store.dispatch("sendOrder", app);

      let lastOrder = JSON.parse(
        window.localStorage.getItem("checkoutInfo")
      );

      var dt = new Date();
      dt.setDate(dt.getDate() + 3);
      lastOrder.delivery_date = dt.toISOString().slice(0, 10);

      lastOrder.order_id = app.apiCallResult.order_id;
      window.localStorage.setItem(
        "lastOrder",
        JSON.stringify(lastOrder)
      );

      // let lastOrder = app.lastOrderInfo
      let order_id = lastOrder.order_id;

      let dados = {
        order_id,
        order_items: lastOrder.order_items,
        delivery_cost: lastOrder.delivery_cost
      };

      app.paymentData = dados;

      await app.$store.dispatch("processPayment", app);

      return false;
    },
  },
  computed: {
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.active {
  border: 2px solid #d12627;
}
</style>