<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="title">{{content.login.reset_password}}</p>
          <div class="inputs">
            <v-text-field v-model="userData.email" :label="content.login.label_email + '*'"></v-text-field>
          </div>
        </div>
        <div class="footer footer-registo">
          <div class="footer-container">
            <p class="msg">{{msg}}</p>
            <a @click="resetPass()" class="white-btn">{{content.login.reset_password}}</a>
            <a v-on:click="changeModal('registo')" class="line-btn"
              >{{content.login.create}}</a
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import api_calls from '@/modules/api_calls'

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog: true,
      userData: {
        email: "",
        password: "",
      },
      show: "",
      msg: "",

      apiCallResult: {},

      notEmptyFields: {
      
    }
    };
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    async resetPass() {
      this.msg = this.content.forms.validation;
      if (this.userData.email == '') {
        this.msg = this.content.forms.email;
      } 
      else{
        let dados = {
        email: this.userData.email
      }
      try {
        this.msg = this.content.forms.validate;
        await api_calls.call_api_put('users/reset-pass', dados)
        this.msg = this.content.forms.new_pass;
        this.$emit("update-modal", "");
      }
      catch (err) {
          this.msg = this.content.forms.new_pass_err
      }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>