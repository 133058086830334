export default {
    data: () => ({
        name: "methodsActions",
        modal: "",
    }),
    methods: {
        updateModal: async function(value) {
            this.n += 1
            this.modal = value;
        },
        changeModal: async function(modal) {
            this.n += 1
            this.$emit("update-modal", modal);
        },
        openFooter: async function(modal) {
            // Event.preventDefault()
            this.$emit("open-footer", modal);
        },
        updateFooter: async function(value) {
            this.footer = value;
        },
        async initialize(endpoint) {
            let app = this
            if (typeof endpoint == 'undefined') {
                console.error("Sem endpoint definido")
                return
            }
            await this.$store.dispatch('collect', { app, endpoint })
        },
        async initializeSlides(endpoint) {
            let app = this
            if (typeof endpoint == 'undefined') {
                console.error("Sem endpoint definido")
                return
            }
            await this.$store.dispatch('collectSlides', { app, endpoint })
        },
        async initializeSingle(endpoint) {
            let app = this
            if (typeof endpoint == 'undefined') {
                console.error("Sem endpoint definido")
                return
            }
            await this.$store.dispatch('getSingle', { app, endpoint })
        },
    }
}