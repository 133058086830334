<template>
  <section class="newsletter" v-if="newsletter == 0">
    <v-card class="newsletter-container" v-if="newsletter_modal == 'max'">
      <a v-if="step == 1" v-on:click="newsletter_modal = 'min'"
        ><span class="icon-icon-close"></span
      ></a>
      <a v-if="step == 2" v-on:click="newsletter = 1"
        ><span class="icon-icon-close"></span
      ></a>
      <div class="info-container" v-if="step == 1">
        <h4>{{ info.title }}</h4>
        <p>{{ info.subtitle }}</p>
        <v-text-field
          v-model="email"
          :label="content.contacts.label_email + '*'"
          dense
        ></v-text-field>
        <v-checkbox v-model="checkbox">
          <template v-slot:label>
            <div class="check-text">
              {{ content.registo.privacy1 }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    v-on:click="openFooter('privacy')"
                    @click.stop
                    v-on="on"
                    class="termos"
                    href="#"
                    >{{ content.registo.privacy2 }}</a
                  >
                </template>
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </div>
      <div class="info-container" v-if="step == 2">
        <p>{{ info.text }}</p>
      </div>
      <div class="footer">
        <div class="footer-container">
          <p class="msg">{{ msg }}</p>
          <a v-if="step == 1" @click="send()" class="white-btn">{{
            content.contacts.btn_form
          }}</a>
          <a v-if="step == 2" v-on:click="newsletter = 1" class="white-btn">{{
            content.forms.close
          }}</a>
        </div>
      </div>
    </v-card>
    <v-card v-if="newsletter_modal == 'min'" class="newsletter-container close">
      <div class="footer">
        <a v-on:click="newsletter_modal = 'max'"
          ><span class="icon-icon-arrow-right close"></span
        ></a>
        <div class="footer-container">
          <h4>{{ info.title }}</h4>
        </div>
      </div>
    </v-card>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from '@/modules/api_calls'

// const mailchimp = require("@mailchimp/mailchimp_marketing");

// mailchimp.setConfig({
//   apiKey: "5a0e4cece259b03e36878b271f29027f-us17",
//   server: "us17",
// });

// const listId = "61efdc68a0";

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["nlinfo"],
  data() {
    return {
      newsletter: 0,
      step: 1,
      newsletter_modal: "max",
      dialog: true,
      checkbox: "",
      email: "",
      msg: "",
      // info: {
      //   title: "Get 15% off",
      //   subtitle: "Just subscribe to our newsletter to receive your code",
      //   text: "Subscrição efectuada com sucesso, Receberá um código promocional e um E-book por email",
      // },
    };
  },
  computed: {
    info() {
      // this.n;
      let temp = {};

      temp = this.nlinfo;
      if (
        typeof temp.content != "undefined" &&
        typeof temp.content == "string"
      ) {
        let content = JSON.parse(temp.content);
        let lang = this.pageLanguage.toLowerCase();
        content = content[lang];

        for (let k in content) {
          temp[k] = content[k];
        }

        temp.content = content;
      }

      return temp;
    },
  },
  methods: {
    changeModal: async function (modal) {
      document.getElementById("form-container").classList.add("close");
      this.$emit("update-modal", modal);
    },
    send: async function () {
      if (this.email == "") {
        this.msg = this.content.forms.email;
      } else if (
        this.checkbox == false ||
        this.checkbox == 0 ||
        this.checkbox == null ||
        this.checkbox == ""
      ) {
        this.msg = this.content.forms.terms;
      } else {
        // const response = await mailchimp.ping.get();
        // console.log(response)

        let email = this.email
        let raw = {
          email_address: email,
          status: "subscribed"
        };
        
        const res = await api_calls.call_api_post('users/mailchimp', raw)

        console.log(res)

        if(res.resultado.title == "Invalid Resource") {
          this.msg = this.content.forms.email2;
        }
        else if(res.resultado.title == "Member Exists") {
          this.msg = this.content.forms.email3;
        }
        else {
          this.step = 2;
          this.msg = "";
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.newsletter {
  .newsletter-container {
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 50;
    width: 350px;
    background-color: white;
    border-radius: 0;
    text-align: center;
    &.close {
      width: 250px;
    }
    .info-container {
      padding: 30px;
      h4 {
        color: black;
        font-size: 18px;
        margin-bottom: 5px;
      }
      .check-text {
        font-size: 10px;
        font-weight: 300;
        line-height: 15px;
      }
      p {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 18px;
      }
      a {
        font-size: 10px;
        font-weight: 300;
        line-height: 15px;
        text-align: left;
        margin: 0;
        width: auto;
        padding: 0;
      }
    }
  }
  .footer {
    height: 90px;
    width: 100%;
    background-color: $red;
    text-align: center;
    position: relative;
    @include responsive(0, 512px) {
      width: 100%;
      left: 0;
    }
    .footer-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      // @include responsive(0, 512px) {
      //     padding-right: 20px;
      // }
      .white-btn {
        @include tp-a-btn(
          fit-content,
          30px,
          13px,
          white,
          transparent,
          0,
          white,
          $red
        );
        letter-spacing: 3px;
        font-weight: 400;
        padding: 0 10px;
        color: white !important;
        border: 1px solid white;
        margin: 0 auto;
        width: 150px;
        padding-bottom: 30px;
        &:hover {
          color: $red !important;
        }
        @include responsive(0, 512px) {
          width: 290px;
          font-size: 12px;
          padding: 0 20px;
        }
      }
      .line-btn {
        color: white !important;
        text-decoration: none;
        border-bottom: 1px solid white;
        display: block;
        letter-spacing: 2px;
        margin: 0 auto;
        font-size: 12px;
        &:hover {
          border-bottom: none;
        }
      }
      h4 {
        color: white;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }
  span {
    position: absolute;
    top: 15px;
    right: 15px;
    font-family: "icomoon";
    font-size: 12px;
    color: $dark-grey;
    &.close {
      top: 50%;
      left: auto;
      right: 20px;
      color: white;
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .icon-icon-close:before {
    content: "\e906";
  }
  .icon-icon-arrow-right:before {
    content: "\e901";
  }
}

.msg {
  width: 100%;
  font-size: 12px;
}
</style>